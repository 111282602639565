

    import { Component, Vue } from 'vue-property-decorator';
    import {
        collection, getDoc, doc, updateDoc, deleteDoc, orderBy, query, getDocs, onSnapshot,
    } from 'firebase/firestore';
    import { onAuthStateChanged, UserInfo } from 'firebase/auth';
    import { auth, firestore } from '@/firebase';

    // db wish interface
    interface WishI {
        id: string,
        title: string,
        url: string,
        img: string,
        price: number,
        category: string,
        reserved: boolean,
        note: string,
    }

    // linked collections

    @Component
    export default class Home extends Vue {

        wishes: WishI[] = [];
        authUser: UserInfo | null = null;

        unsubscribe!: any;

        async created() {
            onAuthStateChanged(auth, (user) => {
                this.authUser = user;
            });

            const wishesQuery = query(collection(firestore, 'wishlist'), orderBy('title', 'asc'));

            this.unsubscribe = onSnapshot(wishesQuery, (wishesSnap) => {
                this.wishes = [];
                wishesSnap.forEach((docSnap) => {
                    const wish = ({ id: docSnap.id, ...docSnap.data() });
                    this.wishes.push(wish as WishI);
                });
            });
        }

        beforeUnmount() {
            this.unsubscribe();
        }

        // METHODS
        reserveWish(idx: any, e: Event) {
            const docToUpdate = doc(firestore, 'wishlist', idx);
            updateDoc(docToUpdate, { reserved: (e.target as HTMLInputElement).checked });
        }

        deleteWish(wishTitle: string, wishId: string) {
            const deletionConfirmed = window.confirm(`Si si istá, že chceš zmazať "${wishTitle}"?`);
            if (deletionConfirmed) {
                const docToDelete = doc(firestore, 'wishlist', wishId);
                deleteDoc(docToDelete);
            }
        }

        editWish(wishId: string) {
            this.$router.push(`/admin?wishid=${wishId}`);
        }

    }
